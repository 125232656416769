import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import NavBar from "./components/NavBar";
import Introduction from "./components/Introduction";
import AboutMe from "./components/AboutMe";
import Services from "./components/Services";
// import Portfolio from "./components/Portfolio";
import Footer from "./components/Footer";
import Maintenance from "./containers/Maintenance";
import { Element } from "react-scroll";

function App() {
  const [loader, setLoader] = useState();
  const [images, setImages] = useState([]);
  const [scrolled, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        if (window.pageYOffset > 80) {
          setScroll(true);
        } else {
          setScroll(false);
        }
      },
      true
    );
  }, []);

  const showAllImg = async () => {
    setLoader(false);
    const imgRes = await fetch("https://picsum.photos/v2/list", {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    });
    const imgData = await imgRes.json();
    setImages(imgData);
    setLoader(true);
  };
  return (
    <>
      <Maintenance></Maintenance>
      {false && (
        <>
          <Router>
            <Switch>
              <Route path="/" exact>
                <NavBar
                  className="position-fixed navi-bar w-100 pl-5 pr-5"
                  style={{
                    backgroundColor: scrolled ? "#000" : "transparent",
                    transition: "all 1s ease"
                  }}
                />
                <Element name="top">
                  <Introduction />
                </Element>
                <Element name="about">
                  <AboutMe />
                </Element>
                <Element name="services">
                  <Services />
                </Element>
                {/* <FunApp /> */}
                {/* <Element name="projects">
        <Portfolio />
      </Element> */}
                <Footer />
              </Route>
              <Route path="/images">
                <div className="container">
                  <Link to="/">{"<"} Back</Link>
                  <h2 className="mt-3 mb-1">Images</h2>
                  <button
                    className="btn btn-primary"
                    onClick={showAllImg}
                    disabled={loader}
                  >
                    Show All
                  </button>
                  <div
                    hidden={loader || true}
                    className="spinner-border text-primary ml-3"
                  ></div>
                  <div className="row">
                    {images.map((image) => (
                      <div
                        className="col-md-4 mt-2"
                        id={image.id}
                        key={image.id}
                      >
                        <a
                          href={image.download_url}
                          className="d-block"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="card">
                            <div className="card-body">
                              <img
                                src={`${image.download_url}`}
                                className="img-fluid"
                                width="300"
                              />
                              <div className="pt-2">
                                <span className="font-weight-bold ">
                                  Author:{" "}
                                </span>
                                {image.author}
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </Route>
            </Switch>
          </Router>
        </>
      )}
    </>
  );
}

const FunApp = () => {
  const [shoe, setShoe] = useState({});
  const [product, setProduct] = useState([
    { id: 1, model: "Nike", amount: 0, desc: "The best shoes in the world" },
    { id: 2, model: "Adiddas", amount: 0, desc: "You will love them" },
    { id: 3, model: "Vans", amount: 0, desc: "Nice for skating" }
  ]);

  const handleShoe = (e) => {
    setShoe({
      id: Math.floor(Math.random() * 100) + 1,
      [e.target.name]: e.target.value
    });
  };

  const addShoe = () => {
    setProduct([...product, shoe]);
  };
  return (
    <>
      <div className="container">
        <Link to="/">{"<"} Back</Link>
        <h2 className="mt-2">Add Shoe</h2>
        <div className="row">
          <div className="col-md-6">
            <input
              type="text"
              name="model"
              placeholder="Add Shoe"
              onChange={(e) => handleShoe(e)}
              className="form-control"
            />
            <textarea
              name="desc"
              placeholder="Add description"
              onChange={(e) => handleShoe(e)}
              className="form-control mt-1"
            ></textarea>
            <button className="btn btn-primary mt-1" onClick={addShoe}>
              +
            </button>
          </div>
        </div>
        <div className="row">
          {product.map((prod) => (
            <div className="col-md-4 mt-1" id={prod.id} key={prod.id}>
              <div className="card">
                <div className="card-header">{prod.model}</div>
                <div className="card-body">{prod.desc}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default App;
