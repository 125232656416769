import React from "react";
import { Card, CardBody } from "reactstrap";
import logoImg from "../../images/edian-reyes-transparent.png";
import pdfResume from "../../Documents/Resume_Edian_Reyes.pdf";
import resumePreviewImg from "../../images/resume_preview.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

export default () => {
  return (
    <>
      <div style={style.centerChilds}>
        <img
          src={logoImg}
          className="img-fluid m-4"
          alt="Edian Reyes Logo"
          width="300"
        />
        <h1 style={style.bigMssg} className="m-4">
          Coming Soon
        </h1>
        {/* <p className="mt-5 mb-0">RESUME / CV</p> */}
        <a
          href={pdfResume}
          target="_blank"
          style={style.anchorResume}
          className="card-link p-relative"
          rel="noopener noreferrer"
        >
          <div className="overlay text-center">
            <p className="card-link-text">
              <FontAwesomeIcon icon={faEye} size="lg" />
            </p>
          </div>
          <Card>
            <CardBody>
              <img src={resumePreviewImg} width={200} />
            </CardBody>
          </Card>
        </a>
      </div>
    </>
  );
};

const style = {
  centerChilds: {
    textAlign: "center",
    display: "flex",
    flexFlow: "column wrap",
    alignItems: "center",
    // justifyContent: "space-evenly",
    height: "100vh",
    position: "fixed",
    width: "100vw"
  },
  bigMssg: {
    fontSize: "5em",
    fontFamily: "Yeseva One"
  },
  anchorResume: {
    fontSize: "2em",
    color: "#00a8ff",
    display: "block"
  }
};
